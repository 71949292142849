require('./bootstrap.js');
//require('./jquery');
//require('./pooper');
//require('./tema');
//require('./service-worker-load');
require('./gtag.js');
//require('./banner.js');
//require('./bootstrap1.js');
//require('./custom.js');

